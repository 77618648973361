import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { remainingTimeForFreePlay } from 'src/utils/freePlayUtils';
import Page from '../components/Page';
import { AppSummaryGames } from '../components/_dashboard/app';
import { CHECK_USER_PLAY } from '../graphql';
import { isFreePlayAvailable } from 'src/utils/freePlayUtils';
import { APP_CONFIG } from 'src/app-config';

const FreeGames = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const [checkUserPlay] = useMutation(CHECK_USER_PLAY, {
    onError: () => {
      if(!isFreePlayAvailable()) {
        navigate('/not-subscribe');
      }
    },
    onCompleted: (data) => {
      if (data.checkUserPlay.responseCode == 700) {
        if(!isFreePlayAvailable()) {
          navigate('/not-subscribe');
        }
      }
    }
  });

  useEffect(() => {
    const msisdn = Cookies.get('msisdn');
    const newUser = localStorage.getItem('newUser');
    const freePlayAvailable = isFreePlayAvailable();
    if (!msisdn && !freePlayAvailable) {
      navigate('/');
    }
    if (!newUser) {
      checkUserPlay();
    }
    // eslint-disable-next-line
  }, []);

  const handleUserPlay = () => {
    localStorage.removeItem('newUser');
    setTimeout(() => {
      checkUserPlay();
    }, 300000);
  };

  const [timerExpired, setTimerExpired] = useState(false);

  const handleTimerEnd = () => {
    setTimerExpired(true);
  };
  const CountdownTimer = ({ initialTime, onTimerEnd }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(interval);
          onTimerEnd();
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [timeLeft, onTimerEnd]);
  
    const formatTime = (time) => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor(time % 60);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
  
    return (
      <Typography variant="h6" component="span" sx={{ ml: 2, color: '#0376c9'  }}>
        Free play remaining: {formatTime(timeLeft)}&nbsp;&nbsp;
      </Typography>
    );
  };

  return (
    <Page title="Open Games | ShujaaArena">
      <Container maxWidth="xl">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4
            }}
          >
            <Typography id="modal-modal-title" variant="p" component="p">
              You are currently using your {APP_CONFIG.FREE_PLAY_TIME} hours free play.
            </Typography>
            <Button onClick={handleClose} sx={{ marginTop: '30px' }}>
              Ok
            </Button>
          </Box>
        </Modal>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '24px'
          }}
        >
          
          {!timerExpired && <CountdownTimer initialTime={remainingTimeForFreePlay()} onTimerEnd={handleTimerEnd} />}
        </Box>
        <AppSummaryGames isPremium={false} handleUserPlay={handleUserPlay} />
      </Container>
    </Page>
  );
};

export default FreeGames;
