import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Alert } from '@mui/material';
import { useQuery } from '@apollo/client';
import { AppGamesList } from '.';
import { GET_SUMMARY_GAMES } from '../../../graphql';
import Spinner from '../../Spinner';

const AppSummaryGames = ({ isPremium, handleUserPlay }) => {
  const { loading, error, data } = useQuery(GET_SUMMARY_GAMES);
  return (
    <Box>
      {loading && <Spinner />}
      {data && (
        <Grid container spacing={3}>
          {data.getSummaryGames.map((summary) => (
            <Grid key={summary.category} item xs={12} md={12} lg={12}>
              <AppGamesList
                isPremium={isPremium}
                summary={summary}
                handleUserPlay={handleUserPlay}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {error && <Alert severity="error">There was an error. Please try again later</Alert>}
    </Box>
  );
};

AppSummaryGames.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  handleUserPlay: PropTypes.func
};

export default AppSummaryGames;
