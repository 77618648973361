import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const NotSubscribed = () => (
  <RootStyle title="Not Subscribed | ShujaArena">
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Box component="img" src="/static/newlogo.png" sx={{ height: 260, mx: 'auto' }} />
          </motion.div>
          <motion.div variants={varBounceIn}>
            <Typography variant="h4" paragraph>
              Not subscribed.
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            Please subscribe to continue playing games.
          </Typography>

          <Button to="/subscribe" size="large" variant="contained"   sx={{
              backgroundColor: '#f3bd34',
              color: '#ffffff',  // White text
              fontSize: '16px',  // Smaller font size
              borderRadius: '8px',  // Smaller border radius
              padding: '8px 15px',  // Smaller padding
              fontFamily: 'abrade',
              '&:hover': {
                backgroundColor: '#FFFF77',
                color: '#ffffff',  // White text on hover as well
              },
            }}  component={RouterLink}>
            Subscribe
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  </RootStyle>
);

export default NotSubscribed;
