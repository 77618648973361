import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Alert, AlertTitle, Button } from '@mui/material';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import { GET_USER_STATUS } from '../graphql';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const Processing = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate('/app/games');
  };
  const [stopFetching, setStopFetching] = React.useState(true);

  const { error, data, loading } = useQuery(GET_USER_STATUS, {
    pollInterval: 2000
  });
  if (data && data.getUserStatus && data.getUserStatus.responseCode === 200) {
    setOpen(true);
  } else if (data && data.getUserStatus && data.getUserStatus.responseCode === 204) {
    navigate('/open/games');
  } else if (data && data.getUserStatus.responseCode === 300) {
    localStorage.setItem('msisdn', data.getUserStatus.msisdn);
    localStorage.setItem('subscribed', '');
    localStorage.setItem('newUser', 'newUser');
    navigate('/open/games');
  } else {
    setTimeout(() => {
      setStopFetching(false);
      navigate('/subscribe');
    }, 1800000);
  }

  useEffect(() => {
    setOpen(true);
    const msisdn = Cookies.get('msisdn');
    if (!msisdn) {
      navigate('/subscribe');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Page title="Processing">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            sx={{ paddingBottom: '5px' }}
          >
            Congratulations
          </Typography>
          <Typography id="modal-modal-title" variant="p" component="p">
            You have subscribed.
          </Typography>
          <Button onClick={handleClose} sx={{ marginTop: '30px' }}>
            Ok
          </Button>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <ContentStyle>
          {loading ||
            (stopFetching && (
              <Alert severity="info">
                <AlertTitle>Please wait...</AlertTitle>
                We are processing your request.
              </Alert>
            ))}
          {error && (
            <Alert severity="error">There was an error subscribing. Please try again later.</Alert>
          )}
        </ContentStyle>
      </Container>
    </Page>
  );
};

export default Processing;
