import { gql } from '@apollo/client';

const GET_FREE_GAMES = gql`
  query GetFreeGames($category: String!) {
    freeGames(category: $category) {
      id
      gametitle
      url
      thumbnailUrl
        title
      startDate
      endDate
      iconUrl
      headerUrl
      canRegister
      tournamentDueTime
      countDown
      categoryId
      tournaCode
    }
  }
`;

export default GET_FREE_GAMES;
