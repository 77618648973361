export default function getUrlParamValue(paramName) {
  const params = window.location.search.substring(1);
  if (params) {
    const index = params.indexOf(paramName);
    let indexEnd;
    if (index !== -1) {
      indexEnd = params.indexOf('&', index + 1);
      if (indexEnd === -1) {
        indexEnd = params.length;
      }
      return params.substring(index + paramName.length + 1, indexEnd);
    }
    return undefined;
  }
}
