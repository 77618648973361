import { gql } from '@apollo/client';

const CHECK_USER_PLAY = gql`
  mutation CheckUserPlay {
    checkUserPlay {
      responseCode
      responseStatus
    }
  }
`;

export default CHECK_USER_PLAY;
