import { APP_CONFIG } from "src/app-config";

const setFreePlayCookie = (name, value, hours) => {
    var expires = "";
    if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const getFreePlayCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const startFreePlay = () => {
    const currentTime = new Date().getTime();
    setFreePlayCookie("freeEpoch", currentTime.toString());
}

const isFreePlayAvailable = () => {
    var startTime = getFreePlayCookie("freeEpoch");
    if (startTime) {
        var currentTime = new Date().getTime();
        var elapsedTime = (currentTime - parseInt(startTime)) / (1000 * 60 * 60);
        return elapsedTime <= APP_CONFIG.FREE_PLAY_TIME;
    }
    return false; 
}
const remainingTimeForFreePlay = () => {
    const startTime = getFreePlayCookie("freeEpoch");
    if (!startTime) {
        startFreePlay();
    }
    const startTimeMillis = parseInt(getFreePlayCookie("freeEpoch"));
    if (startTimeMillis) {
        const currentTimeMillis = new Date().getTime();
        const elapsedTimeSeconds = (currentTimeMillis - startTimeMillis) / 1000;
        const remainingTimeSeconds = APP_CONFIG.FREE_PLAY_TIME * 60 * 60 - elapsedTimeSeconds;
        if (remainingTimeSeconds > 0) {
            return remainingTimeSeconds;
        } else {
            return 0; 
        }
    }
    return undefined;
}

export {
    setFreePlayCookie,
    getFreePlayCookie,
    startFreePlay,
    isFreePlayAvailable,
    remainingTimeForFreePlay
};