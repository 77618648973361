import React from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Grid, Typography } from '@mui/material';

import { GameItem } from '.';

const AppGamesTypeList = (props) => {
  const { title, games } = props;
  return (
    <Box>
      <Typography variant="h4" mb={3}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {games.map((game) => (
          <Grid item xs={12} md={6} lg={4} key={game.id}>
            <GameItem game={game} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

AppGamesTypeList.propTypes = {
  title: PropTypes.string.isRequired,
  games: PropTypes.array.isRequired
};

export default AppGamesTypeList;
