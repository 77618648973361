import { gql } from '@apollo/client';

const GET_SUMMARY_GAMES = gql`
  query GetSummaryGames {
    getSummaryGames {
      category
      games {
        id
        gametitle
        url
        thumbnailUrl

        title
        startDate
        endDate
        iconUrl
        headerUrl
        canRegister
        tournamentDueTime
        countDown
        categoryId
        tournaCode
      }
    }
  }
`;

export default GET_SUMMARY_GAMES;
