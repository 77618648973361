const sidebarConfig = {

  free: [
    {
      title: '---Tournament---',
      path: '/'
    },
    {
      title: 'PUBG',
      path: '/open/games/1'
    },
    {
      title: 'COD',
      path: '/open/games/2'
    },
    {
      title: 'Fall guys',
      path: '/open/games/3'
    },
    {
      title: 'Freefire',
      path: '/open/games/4'
    },
    {
      title: 'Minecraft',
      path: '/open/games/5'
    },
    {
      title: 'Overwatch',
      path: '/open/games/6'
    },
    {
      title: 'Rocket league',
      path: '/open/games/7'
    },
    {
      title: 'CSGO',
      path: '/open/games/8'
    },
    {
      title: 'Sunday roblox',
      path: '/open/games/9'
    },
    {
      title: 'Fortnite',
      path: '/open/games/10'
    },
    {
      title: 'League of legends',
      path: '/open/games/11'
    },
    {
      title: 'Rainbow 6',
      path: '/open/games/12'
    },
    {
      title: 'Overwatch 2',
      path: '/open/games/13'
    },
    {
      title: 'Roblox',
      path: '/open/games/14'
    },
    {
      title: 'FIFA',
      path: '/open/games/15'
    },
  
    {
      title: 'Street fighter 6',
      path: '/open/games/16'
    }
  ]
};

export default sidebarConfig;
