
import React, { useState, useEffect } from 'react';

function parseHashInXML(xml) {
  // const code = xml.getElementsByTagName('ns0:ResponseCode')?.[0]?.innerHTML;

  const responseCode = xml.getElementsByTagName('ns0:ResponseCode')[0].textContent;


  if (responseCode === undefined) {
    // Reload the page if responseCode is undefined
    location.reload();
    return; // Reload might not return, but including this for clarity
  }

  
  if (responseCode === '0') {
    const responseMsg = xml.getElementsByTagName('ns0:ResponseMsg')[0].textContent;
    const responseRefId = xml.getElementsByTagName('ns0:ResponseRefId')[0].textContent;
    const msisdnHash = xml.getElementsByTagName('ns1:MsisdnHash')[0].textContent;
    const blazeSubscriber = xml.getElementsByTagName('ns1:BlazeSubscriber')[0].textContent;

    // console.log({ responseCode, responseMsg, msisdnHash,blazeSubscriber })
    return msisdnHash;
  }
  else if (responseCode === '1') {
    console.log('Response code is 1');

    const responseMsg = xml.getElementsByTagName('ns0:ResponseMsg')[0].textContent;
    // Log the extracted values
    // console.log('Response Code:', responseCode);
    // console.log('Response Message:', responseMsg);

    return "no msisdn";



  } else {
    console.log('Response code is neither 0 nor 1');
  }
}

export async function checkHashHeaderSync() {
  let t;
  try {
    t = await checkHashHeader();
  } catch {
    return ('no msisdn');
  }
  return t;
}

export function checkHashHeader() {
  return new Promise((resolve, reject) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4) {
        if (xmlHttp.status == 200) {
          // console.log(xmlHttp)

          const phoneHash = parseHashInXML(xmlHttp.responseXML);
          if (phoneHash.length > 0) {
            resolve(phoneHash);
          } else {
            reject(new Error('No msisdn'));
          }
        } else {
          reject(new Error('Request failed'));
        }
      }
    };
    xmlHttp.open('GET', 'http://header.safaricombeats.co.ke/', true);
    xmlHttp.send(null);
  });
}


export function userStatus(msisdnhash) {
  return new Promise((resolve, reject) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4) {
        if (xmlHttp.status == 200) {

          // const phoneHash = parseHashInXML(xmlHttp.responseXML);
          resolve(xmlHttp.responseText);
        } else {
          reject(new Error('Request failed'));
        }
      }
    };
    xmlHttp.open('GET', 'http://41.72.208.46:9021/public/v1/user/${msisdnhash}', true);
    xmlHttp.send(null);
  });
}


export async function checkUserStatusSync(msisdnhash) {
  let t;
  t = await userStatus(msisdnhash);

  return t;
}



export function gamePoaHeader() {
  return new Promise((resolve, reject) => {
    const xmlHttp = new XMLHttpRequest();

    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4) {
        console.log("** Request Headers:");
        for (const header in xmlHttp.setRequestHeader) {
          if (typeof xmlHttp.setRequestHeader[header] !== 'function') {
            console.log(`  - ${header}: ${xmlHttp.setRequestHeader[header]}`);
          }
        }

        if (xmlHttp.status == 200) {
          console.log("** Response Headers:");
          for (const header in xmlHttp.getAllResponseHeaders()) {
            console.log(`  - ${header}: ${xmlHttp.getAllResponseHeaders()[header]}`);
          }

          resolve(xmlHttp.responseText);
        } else {
          reject(new Error(`Request failed with status: ${xmlHttp.status}`));
        }
      }
    };

    xmlHttp.open('GET', `http://www.gamespoa.com/`, true);

    console.log("** Request URL:");


    xmlHttp.send(null);
  });
}