import { gql } from '@apollo/client';

const CHECK_OTP = gql`
  mutation CheckOtp($otp: String!, $msisdn: String!) {
    checkOtp(otp: $otp, msisdn: $msisdn) {
      responseCode
      responseStatus
    }
  }
`;

export default CHECK_OTP;
