import { gql } from '@apollo/client';

const SUBSCRIBE_USER = gql`
  mutation SubscribeUser {
    subscribeUser {
      responseCode
      responseStatus
      msisdn
    }
  }
`;

export default SUBSCRIBE_USER;
