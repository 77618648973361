import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import closeIcon from '@iconify/icons-eva/close-fill'; // Icon for closing the drawer
// material
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Typography, Stack, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import { useNavigate } from 'react-router-dom';
//
import Searchbar from './Searchbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.elevated, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const MenuStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
  '& > *': {
    marginLeft: theme.spacing(2) // Spacing between buttons
  }
}));

const MenuButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  },
  '& .MuiTypography-root': {
    fontWeight: 500,
    textTransform: 'none' // Prevent all-uppercase text
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate(); // Create navigate instance
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false); // Track right drawer state

  // Handlers for navigation
  const handleHomeClick = () => navigate('/open/games/Adventure');
  const handleAnalyticsClick = () => navigate('/open/games/Adventure');
  const handleReportsClick = () => navigate('/open/games/Adventure');
  const handleSettingsClick = () => navigate('/open/games/Adventure');

  const toggleRightDrawer = () => setRightDrawerOpen(!rightDrawerOpen);

  const menuItems = [
    { label: 'Home', action: handleHomeClick },
    { label: 'Tournament', action: handleAnalyticsClick },
    { label: '2Player', action: handleReportsClick },
    { label: 'Settings', action: handleSettingsClick }
  ];

  return (
    <RootStyle>
      <ToolbarStyle>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            {/* Left-side menu toggle */}
            <MHidden width="lgUp">
              <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Icon icon={menu2Fill} />
              </IconButton>
            </MHidden>
            <Typography color="#f3bd34" variant="h4">
              Shujaa Arena
            </Typography>
          </Stack>

          <Searchbar />

          {/* Horizontal Menu for Full Screen */}
          <MHidden width="mdDown">
            <MenuStyle>
              {menuItems.map((item, index) => (
                <MenuButton key={index} onClick={item.action}>
                  <Typography variant="subtitle1">{item.label}</Typography>
                </MenuButton>
              ))}
            </MenuStyle>
          </MHidden>

          {/* Right-side Drawer Menu for Mobile */}
          <MHidden width="lgUp">
            <IconButton onClick={toggleRightDrawer} sx={{ color: 'text.primary' }}>
              <Icon icon={menu2Fill} /> {/* Use the same menu button for right drawer */}
            </IconButton>
          </MHidden>
        </Stack>

        {/* Right Drawer for Mobile */}
        <MHidden width="lgUp">
          <Drawer
            anchor="right" // Drawer slides in from the right
            open={rightDrawerOpen}
            onClose={toggleRightDrawer}
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            <IconButton onClick={toggleRightDrawer} sx={{ mt: 1, ml: 1 }}>
              <Icon icon={closeIcon} /> {/* Close Drawer Icon */}
            </IconButton>
            <List>
              {menuItems.map((item, index) => (
                <ListItem button key={index} onClick={item.action}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </MHidden>
      </ToolbarStyle>
    </RootStyle>
  );
}
