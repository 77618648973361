import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import Iframe from './Iframe';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenDialog = ({ open, handleClose, game }) => {

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {game.gametitle}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose} aria-label="close">
            Close <Icon icon={closeCircleFill} width={20} height={20} />
          </Button>
        </Toolbar>
      </AppBar>
      <Box height="100%">
        <Iframe title={game.gametitle} src={game.url} width="100%" height="100%" />
      </Box>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  game: PropTypes.object.isRequired,
};

export default FullScreenDialog;
