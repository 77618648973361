import { gql } from '@apollo/client';

const GET_TOURNAMENT = gql`
  query GetTournament($id: ID!) {
    tournament(id: $id) {
      id
      title
      startDate
      endDate
      iconUrl
      headerUrl
      canRegister
      tournamentDueTime
      countDown
      categoryId
      tournaCode
    }
  }
`;

export default GET_TOURNAMENT;
