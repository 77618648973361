import { gql } from '@apollo/client';

const GENERATE_OTP = gql`
  mutation GenerateOtp($msisdn: String!, $ipaddress: String!) {
    generateOtp(msisdn: $msisdn,ipaddress:$ipaddress) {
      responseCode
      responseStatus
    }
  }
`;

export default GENERATE_OTP;
