// routes
import React from 'react';
import TagManager from 'react-gtm-module';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  React.useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      events: {
        userSubscribed: 'User Subscribed'
      }
    });
  }, []);
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Router />
    </ThemeConfig>
  );
}
