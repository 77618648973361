import { gql } from '@apollo/client';

const GET_GAME_CATEGORIES = gql`
  query GetGameCategories {
    gameCategories {
      id
      gamecategory
    }
  }
`;

export default GET_GAME_CATEGORIES;
