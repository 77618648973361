import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Subscribe from '../components/Subscribe';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const SubscribePage = () => (
  <Page title="Subscribe">
    <Container maxWidth="xl">
      <ContentStyle>
        <Subscribe />
      </ContentStyle>
    </Container>
  </Page>
);

export default SubscribePage;
