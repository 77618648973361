import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import { Box, Stack, Card, Button, Divider, CardHeader } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { GameItem } from '.';

const AppGamesList = (props) => {
  const { summary, isPremium, handleUserPlay } = props;
  return (
    <Card>
      <CardHeader title={`${summary.category} Games`} />

      <Scrollbar>
        <Stack direction="row" spacing={3} sx={{ p: 3, pr: 0 }}>
          {summary.games.map((game) => (
            <Box width="250px" key={game.id}>
              <GameItem game={game} handleUserPlay={handleUserPlay} />
            </Box>
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to={isPremium ? `/app/games/${summary.category}` : `/open/games/${summary.category}`}
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          More
        </Button>
      </Box>
    </Card>
  );
};

AppGamesList.propTypes = {
  summary: PropTypes.object.isRequired,
  isPremium: PropTypes.bool.isRequired,
  handleUserPlay: PropTypes.func
};

export default AppGamesList;
