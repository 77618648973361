import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ title, src, width, height }) => (
  <iframe title={title} src={src} width={width} height={height} />
);

Iframe.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};

export default Iframe;
