import { gql } from '@apollo/client';

const GET_PREMIUM_GAMES = gql`
  query GetPremiumGames($category: String!) {
    premiumGames(category: $category) {
      id
      gametitle
      url
      thumbnailUrl
    }
  }
`;

export default GET_PREMIUM_GAMES;
