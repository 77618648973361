import { gql } from '@apollo/client';

const GET_USER_STATUS = gql`
  query GetUserStatus {
    getUserStatus {
      responseCode
      responseStatus
      msisdn
    }
  }
`;

export default GET_USER_STATUS;
