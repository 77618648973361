import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
import { checkHashHeader } from 'src/utils/checkHashHeader';
import { useState } from 'react';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center', // Center content horizontally
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

const wifiMessage = (
  <div>
    <motion.div variants={varBounceIn}>
      <Typography variant="h4" paragraph>
        WiFi detected.
      </Typography>
    </motion.div>
    <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
      Please switch to mobile data and refresh the page in order to sign in.
    </Typography>
  </div>
);

let message;
let setMessage;
let navigate;

const HeDisable = () => {
  checkHashHeader().then(
    () => {
      navigate('/home');
    },
    () => {
      setMessage(wifiMessage);
    }
  );
  navigate = useNavigate();
  [message, setMessage] = useState('');
  
  return (
    <RootStyle title="WiFi detected | ShujaArena">
      <Container>
        <MotionContainer initial="initial" open>
          <Box 
            sx={{ 
              maxWidth: 480, 
              margin: 'auto', 
              textAlign: 'center', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              minHeight: '100vh' // Center vertically as well
            }}
          >
            <motion.div variants={varBounceIn}>
              <Box component="img" src="/static/newlogo.png" sx={{ height: 200, mx: 'auto', mb: 4 }} />
            </motion.div>
            {message}
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default HeDisable;
