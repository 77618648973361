import PropTypes from 'prop-types';

import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src="/static/newlogo.png" sx={{ width: 200, height: 200, ...sx }} />;
}
