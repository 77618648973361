import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Card, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FullScreenDialog from '../../FullScreenDialog';

const GameImgStyle = styled('img')({
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  borderRadius: '5px 5px 0 0',
});

// Function to generate access token
const generateAuthToken = async (client_id, client_secret) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'client_credentials');

  const credentials = btoa(`${client_id}:${client_secret}`);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${credentials}`,
    },
    body: urlencoded,
    redirect: 'follow',
  };

  try {
    // Send the request to the authentication endpoint
    const response = await fetch('https://shujaarena.com/auth/v1/login', requestOptions);

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Assuming the response is JSON
    console.log('Token Response:', data);
    return data.access_token; // Return the access token
  } catch (error) {
    console.error('Error generating auth token:', error);
    throw error; // Re-throw the error after logging
  }
};
const GameItem = ({ game, handleUserPlay, authToken }) => {
  // Use fallback values if some fields are null
  const { gametitle, thumbnailUrl, title, headerUrl, countDown, tournamentDueTime, startDate, tournaCode } = game;
  const displayTitle = gametitle || title; // Fallback to 'title' if 'gametitle' is null
  const displayThumbnail = thumbnailUrl || headerUrl; // Fallback to 'iconUrl' if 'thumbnailUrl' is null

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    if (tournaCode) {
      // Perform GET request to the tournament joining URL if tournaCode exists
      const token = await generateAuthToken("sjradmin", "sjradmin");
      try {

          // Fetch the public IP address
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const userIpAddress = ipData.ip;
        
        const response = await fetch(`https://shujaarena.com/shujaa/api/v1/tournaments/join/254120120129?tournaCode=${tournaCode}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'XA-User-Agent': navigator.userAgent,
            'X-IP-Address': userIpAddress, 

          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Join Tournament Response:', data);
        // Optionally handle the response data as needed
      } catch (error) {
        console.error('Error joining tournament:', error);
      }
    } else {
      // Open dialog if tournaCode does not exist
      setOpen(true);
      if (handleUserPlay) {
        handleUserPlay();
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        borderRadius: 2, // Rounded corners
        boxShadow: 3,    // Shadow for raised effect
        overflow: 'hidden', // Ensure the image respects the rounded corners
        transition: '0.3s', // Smooth transition for hover effects
        '&:hover': {
          boxShadow: 6, // Deeper shadow on hover
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <Box style={{ mt: 2, position: 'relative', width: 250, height: 140 }} xs={{ height: 160 }}>
          <GameImgStyle alt={displayTitle} src={displayThumbnail} />
        </Box>
        <Stack
          spacing={2}
          alignItems="stretch"
          justifyContent="space-between"
          py={2}
          pr={2}
          width="100%"
        >
          <Typography variant="h6" textAlign="left">
            {displayTitle} {/* Display the fallback title if necessary */}
          </Typography>

          {/* Additional tournament details */}
          {startDate && (
            <Typography variant="body2" color="textSecondary">
              Start Date: {new Date(startDate).toLocaleString()} {/* Format start date */}
            </Typography>
          )}
          {countDown && (
            <Typography variant="body2" color="textSecondary">
              Countdown: {countDown}
            </Typography>
          )}
          {tournamentDueTime && (
            <Typography variant="body2" color="textSecondary">
              Tournament Due: {tournamentDueTime}
            </Typography>
          )}
          {tournaCode && (
            <Typography variant="body2" color="textSecondary">
              Tournament Code: {tournaCode}
            </Typography>
          )}

          <Button
            fullWidth
            size="small"
            sx={{ backgroundColor: '#f3bd34', '&:hover': { backgroundColor: '#e3ad24' } }}
            variant="contained"
            onClick={handleClickOpen}
          >
            {tournaCode ? 'Join Tournament' : 'Play'} {/* Conditional button text */}
          </Button>
        </Stack>
      </Stack>
      <FullScreenDialog open={open} handleClose={handleClose} game={game} />
    </Card>
  );
};

GameItem.propTypes = {
  game: PropTypes.object.isRequired,
  handleUserPlay: PropTypes.func,
  authToken: PropTypes.string.isRequired, // Ensure authToken is passed as a prop
};

export default GameItem;
