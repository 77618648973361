import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container, Button } from '@mui/material';
// components
import { MotionContainer } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const TermsAndConditions = () => (
  <RootStyle title="Terms & Conditions | ShujaaArena">
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ margin: 'auto', textAlign: 'left' }}>
          <Typography variant="h4" paragraph>
            Terms and Conditions
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            ShujaaArena is a service developed, owned, and maintained by Solunet Business Solutions
            Limited.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            By accessing this portal, you confirm that you have read, understood, and agree to comply with these terms and conditions.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            ShujaaArena reserves the right to modify these terms and conditions ("Terms") or discontinue the service at any time, without prior notice. It is your responsibility to review these Terms regularly. If you disagree with any of the Terms or changes made to them, you should not use this site or service.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            The content available on this site (e.g., games, data, software, text, images, sounds, music, photographs, communications, or other materials) is provided for personal, non-commercial use only. All content provided through the service is owned by ShujaArena, its affiliates, or third-party content providers. Copyright in the content belongs to ShujaArena or the respective creators.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            ShujaaArena strives to provide content that respects ethnic and regional sensitivities. In the event any content is found to be offensive, it is entirely unintentional. You agree that any content transmitted or downloaded via this service is at your own risk, and ShujaArena is not responsible for any harm or damage to your device or data loss.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            By subscribing to ShujaaArena, you consent to receiving SMS texts and links related to game content, as well as promotional SMS messages.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            To enjoy the games and services, users must be active subscribers. Subscription charges are Ksh 10 per day, Ksh 50 per week, or Ksh 150 per month. This is an auto-renewal service.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            All standard operator terms and conditions apply.
          </Typography>
          <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
            Please note that data charges will apply according to your mobile plan.
          </Typography>
        </Box>
        <Box sx={{ margin: 'auto', textAlign: 'center' }}>
          <Button
          sx={{
            backgroundColor: '#f3bd34',
            color: '#ffffff',  // White text
            fontSize: '16px',  // Smaller font size
            borderRadius: '8px',  // Smaller border radius
            padding: '8px 15px',  // Smaller padding
            fontFamily: 'abrade',
            '&:hover': {
              backgroundColor: '#FFFF77',
              color: '#ffffff',  // White text on hover as well
            },
          }}            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Subscribe
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  </RootStyle>
);

export default TermsAndConditions;
