import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import { CHECK_OTP, GENERATE_OTP } from '../../../graphql';

const ConfirmOtpForm = ({ msisdn, setShowOtp }) => {
  const navigate = useNavigate();
  const [resendOtp, setResendOtp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendOtp(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  const [CheckOtp, { data: checkData, error: checkError, loading: checkLoading }] = useMutation(CHECK_OTP);
  const [GenerateOtp, { loading: generateLoading, error: generateError }] = useMutation(GENERATE_OTP);

  const LoginSchema = Yup.object().shape({
    otp: Yup.number().required('OTP is required')
  });

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ otp }) => {
      try {
        const response = await CheckOtp({ variables: { msisdn, otp: `${otp}` } });
        console.log('OTP:', response.data);
        console.log('msisdn***:', msisdn);

        if (response.data.checkOtp.responseCode === 200 || response.data.checkOtp.responseStatus === "exist otp") {
          // localStorage.setItem('msisdn', msisdn);
          Cookies.set('msisdn', msisdn);

          
          setShowOtp(false);
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.error('Error confirming OTP:', err);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} my={4}>
          <TextField
            fullWidth
            type="number"
            label="OTP"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
            value={formik.values.otp}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ backgroundColor: '#f3bd34', '&:hover': { backgroundColor: '#e3ad24' } }}

          loading={isSubmitting || checkLoading}
        >
          Confirm OTP
        </LoadingButton>
      </Form>
      {(checkError || generateError) && (
        <Alert severity="error">
          {checkError?.message === '404: Not Found'
            ? 'Invalid OTP.'
            : 'There was an error confirming the OTP provided.'}
          {generateError && 'There was an error generating the OTP.'}
        </Alert>
      )}
      {resendOtp && (
        <LoadingButton
          fullWidth
          size="large"
          variant="outlined"
          loading={generateLoading}
          onClick={async () => {
            await GenerateOtp({ variables: { msisdn } });
            setResendOtp(false);
          }}
          sx={{
            marginTop: '16px'
          }}
        >
          Resend OTP
        </LoadingButton>
      )}
    </FormikProvider>
  );
};

ConfirmOtpForm.propTypes = {
  msisdn: PropTypes.string.isRequired,
  setShowOtp: PropTypes.func.isRequired
};

export default ConfirmOtpForm;
