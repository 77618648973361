import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import getPhoneNumber from '../../../utils/getPhoneNumber';
import { GENERATE_OTP } from '../../../graphql';
import Cookies from 'js-cookie';

const LoginForm = ({ setShowOtp, setMsisdn }) => {
  const [GenerateOtp, { data, loading, error }] = useMutation(GENERATE_OTP);

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Phone number is required')
  });

  const formik = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ phone }) => {
      const msisdn = getPhoneNumber(phone);
      const ipaddress="127.0.0.1"

      Cookies.set('msisdn', msisdn);
      localStorage.setItem('msisdn', msisdn);
      try {
        const response = await GenerateOtp({ variables: { msisdn,ipaddress } });
        console.log('OTP generation:', response.data.message);

        if (response.data.generateOtp.responseCode === 200 || response.data.generateOtp.responseStatus === "ok") {
          localStorage.setItem('msisdn', msisdn);
          Cookies.set('msisdn', msisdn);
          setMsisdn(msisdn);
          setShowOtp(true);
        } else {
          console.log('OTP generation failed:', response);
        }
      } catch (err) {
        console.error('Error generating OTP:', err);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} my={4}>
          <TextField
            fullWidth
            type="tel"
            label="Phone number"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ backgroundColor: '#f3bd34', '&:hover': { backgroundColor: '#e3ad24' } }}

          loading={isSubmitting || loading}
        >
          Confirm phone number
        </LoadingButton>
        {error && (
          <Alert severity="error">
            {error.message === '404: Not Found' ? error.message : 'There was an error. Please try again later.'}
          </Alert>
        )}
      </Form>
    </FormikProvider>
  );
};

LoginForm.propTypes = {
  setShowOtp: PropTypes.func.isRequired,
  setMsisdn: PropTypes.func.isRequired
};

export default LoginForm;
