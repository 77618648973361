// GraphQL queries
export { default as CHECK_USER } from './queries/checkUser';
export { default as GET_USER_STATUS } from './queries/getUserStatus';
export { default as GET_GAME_CATEGORIES } from './queries/gameCategories';
export { default as GET_FREE_GAMES } from './queries/freeGames';
export { default as GET_PREMIUM_GAMES } from './queries/premiumGames';
export { default as GET_SUMMARY_GAMES } from './queries/summaryGames';
export { default as GET_USER_CATEGORY_GAMES } from './queries/getUserCategoryGames';
export { default as GET_TOURNAMENT } from './queries/tournamentGames';

// GraphQL mutations
export { default as CHECK_OTP } from './mutations/checkOtp';
export { default as GENERATE_OTP } from './mutations/generateOtp';
export { default as SUBSCRIBE_USER } from './mutations/subscribeUser';
export { default as CHECK_USER_PLAY } from './mutations/checkUserPlay';
