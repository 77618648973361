import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { remainingTimeForFreePlay } from 'src/utils/freePlayUtils';
import { GET_USER_STATUS, CHECK_USER_PLAY } from 'src/graphql';
import TagManager from 'react-gtm-module';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Box } from '@mui/material';
import getErrorCode from '../utils/getErrorCode';
import Page from '../components/Page';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const ContainerStyle = styled(Container)(({ theme }) => ({
  marginLeft: '0',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const CenteredBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
});

const ProceedPage = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  let userPlayFinishFlag = false;
  let userStatusFinishFlag = false;

  const [checkUserPlay] = useMutation(CHECK_USER_PLAY, {
    onCompleted: (data) => {
      if (data.checkUserPlay.responseCode == 800) {
        navigate('/open/games');
        return;
      }
      if (data.checkUserPlay.responseCode == 700) {
        navigate('/subscribe');
        return;
      }
      if (userStatusFinishFlag) {
        navigate('/auth');
        return;
      }
      userPlayFinishFlag = true;
    }
  });

  useEffect(() => {
    checkUserPlay();
  }, []);

  const { error, data, loading } = useQuery(GET_USER_STATUS, {
    onCompleted: (data) => {
      const msisdn = Cookies.get('msisdn');
      if (!msisdn) {
        setMessage('Please turn off WiFi and use your mobile data.');
      } else {
        if (error && error.graphQLErrors && getErrorCode(error) === 404) {
          localStorage.setItem('subscribed', '');
        }
        if (data && data.getUserStatus && data.getUserStatus.msisdn) {
          if (data.getUserStatus.responseCode === 200) {
            localStorage.setItem('msisdn', data.getUserStatus.msisdn);
            localStorage.setItem('subscribed', true);
            TagManager.dataLayer({
              dataLayer: {
                event: 'userSubscribed',
                userId: data.getUserStatus.msisdn
              }
            });
            navigate('/app/games');
          } else if (data.getUserStatus.responseCode === 300) {
            localStorage.setItem('msisdn', data.getUserStatus.msisdn);
            localStorage.setItem('subscribed', '');
            localStorage.setItem('newUser', 'newUser');
            navigate('/open/games');
          }
        } else if (userPlayFinishFlag) {
          navigate('/auth');
        } else {
          userStatusFinishFlag = true;
        }
      }
    }
  });

  const proceed = () => {
    const remainingTime = remainingTimeForFreePlay();
    if (remainingTime && remainingTime > 0) {
      navigate('/open/games');
    } else {
      navigate('/');
    }
  };

  return (
    <Page
      title="ShujaaArena | subscribe"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',   
        height: '100vh',              
        textAlign: 'center',          
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
      }}
    >
      <CenteredBox sx={{ background: 'rgba(255,255,255,0.4)', height: '100%' }}>
        <Box
          component="img"
          src="/static/newlogo.png" 
          sx={{ width: 200, margin: '0px auto', padding: '50px 0' }}
        />

        <ContainerStyle>
          <Typography variant="h5" sx={{ color: 'black', fontFamily: 'lemonmilk' }}>
            GET READY FOR AN AMAZING EXPERIENCE!
            <br />
            Shujaa
            
          </Typography>
          <Typography
            paragraph="true"
            sx={{
              textAlign: 'center',
              color: 'red',
              marginTop: '20px',
              fontWeight: 'bold',
            }}
          >
            {message}
          </Typography>
          <Button
            size="small"
            onClick={proceed}
            aria-label="close"
            sx={{
              backgroundColor: '#f3bd34',
              color: '#ffffff',  // White text
              fontSize: '16px',  // Smaller font size
              borderRadius: '8px',  // Smaller border radius
              padding: '8px 15px',  // Smaller padding
              fontFamily: 'abrade',
              '&:hover': {
                backgroundColor: '#FFFF77',
                color: '#ffffff',  // White text on hover as well
              },
            }}
          >
            Start
          </Button>

          <Typography
            paragraph="true"
            sx={{ textAlign: 'center', color: 'black', marginTop: '20px',fontWeight: 'bold' }}
          >
            For any question call 0748503275 for assistance.
          </Typography>

          {/* Social media icons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
            <FacebookIcon sx={{ color: '#3b5998', fontSize: 40 }} />
            <TwitterIcon sx={{ color: '#00acee', fontSize: 40 }} />
            <InstagramIcon sx={{ color: '#c32aa3', fontSize: 40 }} />
          </Box>
        </ContainerStyle>
      </CenteredBox>
    </Page>
  );
};

export default ProceedPage;
