import { gql } from '@apollo/client';

const CHECK_USER = gql`
  query CheckUser {
    checkUser {
      id
      gametitle
      url
    }
  }
`;

export default CHECK_USER;
