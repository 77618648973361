import { gql } from '@apollo/client';

const GET_USER_CATEGORY_GAMES = gql`
  query GetUserCategoryGames($category: String!) {
    getUserCategoryGames(category: $category) {
      id
      gametitle
      url
      thumbnailUrl
    }
  }
`;

export default GET_USER_CATEGORY_GAMES;
