import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, AppBar, Toolbar, List, ListItem, ListItemText } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
}));

const BannerStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  padding: theme.spacing(4),
  background: '#f0f0f0',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const PrizeStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const LogoStyle = styled('img')(({ theme }) => ({
  width: '150px', // Adjust size as necessary
}));

const CharacterStyle = styled('img')(({ theme, position }) => ({
  width: '100px', // Adjust size as necessary
  position: 'absolute',
  top: position === 'top-left' ? '20px' : '20px',
  left: position === 'top-left' ? '20px' : 'auto',
  right: position === 'top-right' ? '20px' : 'auto',
}));

const JoinButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#3f51b5', // Customize button color
  color: 'white', // Customize button text color
}));

const MenuBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <RootStyle title="Home | ShujaaArena App">
      {/* Navigation Menu */}
      <MenuBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ShujaaArena
          </Typography>
          <List sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <ListItem component={RouterLink} to="/" button>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem component={RouterLink} to="/tournament" button>
              <ListItemText primary="Tournament" />
            </ListItem>
            <ListItem component={RouterLink} to="/about" button>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem component={RouterLink} to="/contact" button>
              <ListItemText primary="Contact" />
            </ListItem>
          </List>
        </Toolbar>
      </MenuBar>

      <MotionContainer initial="initial" open>
        <BannerStyle>
          <LogoStyle src="overwatch-logo.png" alt="Overwatch Logo" />
          <CharacterStyle src="genji.png" alt="Genji" position="top-left" />
          <CharacterStyle src="tracer.png" alt="Tracer" position="top-right" />

          <motion.h1 variants={varBounceIn}>JOIN THE TOURNAMENT</motion.h1>
          <motion.h2 variants={varBounceIn}>AND WIN</motion.h2>

          <PrizeStyle>
            <img src="netflix-logo.svg" alt="Netflix" />
            <img src="spotify-logo.svg" alt="Spotify" />
            <img src="playstation-logo.svg" alt="PlayStation Store" />
          </PrizeStyle>

          <JoinButton
            variant="contained"
            size="large"
            component={RouterLink}
            to="/join" // Adjust this route as needed
          >
            JOIN NOW
          </JoinButton>
        </BannerStyle>
      </MotionContainer>
    </RootStyle>
  );
}
